import React from 'react';
import Data from '../../Data';
import Input from '../Input/';

class UniversalChannelRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      direction: 0,
      type: 0
    }
  }
  static getDerivedStateFromProps(props,state) {
    if (props.expansion) {
      state.direction=props.data["E"+props.expansion+"_Cfg_Net.Dir_U"+props.index].value
      state.type=props.data["E"+props.expansion+"_Mask.Type_U"+props.index].value
    } else {
      state.direction=props.data["U"+props.index+".Direction_In"].value
      state.type=props.data["U"+props.index+".Type_In"].value
    }
    return state;
  }
  setDirection(e) {
    this.setState({
      direction:e.target.value
    })
    this.updateField(e);
  }
  setType(e) {
    this.setState({
      type:e.target.value
    })
    this.updateField(e);
  }
  updateField(e) {
    Data.updateField(e.target.id, this.props.data[e.target.id].type, e)
  }
  getFilterIndex() {
    var typeValue = this.state.type;

    switch (true) {
      case (parseInt(typeValue) === 16):
        return 1;

      case (typeValue > 16):
        return 2;

      default:
        return 0;
    }
  }
  getMinMaxField(scope) {
    if (parseInt(this.state.direction) === 1) {
      return "—";
    }

    var typeValue = this.state.type;

    if (scope === null) {
      scope = "Min"
    }

    var field = (scope==="Min"?this.getFieldNameMin():this.getFieldNameMax());

    switch (true) {
      case (typeValue > 9 && typeValue < 16):
        return (
          <Input
            onChange={this.updateField.bind(this)}
            id={field}
            default={this.props.data[field].default}
            value={this.props.data[field].value}
            datatype={this.props.data[field].type}
            datarange={this.props.data[field].range}
            isvalid={Data.fieldIsValid(field)} />
        )
      default:
        return "—";
    }
  }
  getFieldNameType() {
    if (this.props.expansion) {
      return "E"+this.props.expansion+"_Mask.Type_U"+this.props.index;
    }

    return "U"+this.props.index+".Type_In";
  }
  getFieldNameDirectionIn() {
    if (this.props.expansion) {
      return "E"+this.props.expansion+"_Cfg_Net.Dir_U"+this.props.index;
    }
    return "U"+this.props.index+".Direction_In";
  }
  getFieldNameFilter() {
    if (this.props.expansion) {
      return "E"+this.props.expansion+"_Cfg_Net.Filter_U"+this.props.index;
    }

    return "U"+this.props.index+".Filter";
  }
  getFieldNameMin() {
    if (this.props.expansion) {
      return "E"+this.props.expansion+"_Cfg_Net.Min_U"+this.props.index;
    }

    return "U"+this.props.index+".Min_Value";
  }
  getFieldNameMax() {
    if (this.props.expansion) {
      return "E"+this.props.expansion+"_Cfg_Net.Max_U"+this.props.index;
    }

    return "U"+this.props.index+".Max_Value";
  }
  getFieldNameOSM() {
    if (this.props.expansion) {
      return "E"+this.props.expansion+"_Cfg_Net.OSM_Val_U"+this.props.index;
    }

    return "U"+this.props.index+".OSM_Value";
  }
  getFieldNameOverrEn() {
    if (this.props.expansion) {
      return "E"+this.props.expansion+"_OvrLog.Ovr_En_U"+this.props.index;
    }

    return "U"+this.props.index+".Overr_En";
  }
  getFieldNameOverrVal() {
    if (this.props.expansion) {
      return "E"+this.props.expansion+"_OvrLog.Ovr_Val_U"+this.props.index;
    }

    return "U"+this.props.index+".Overr_Val";
  }
  render() {
    if (parseInt(this.state.direction) === 1) {
      return (
        <tr>
          <td className="row-label">Universal Channel {this.props.index}</td>
          <td>
            <Input
              onChange={this.setDirection.bind(this)}
              id={this.getFieldNameDirectionIn()}
              default={this.props.data[this.getFieldNameDirectionIn()].default}
              value={this.props.data[this.getFieldNameDirectionIn()].value}
              datatype={this.props.data[this.getFieldNameDirectionIn()].type}
              datarange={this.props.data[this.getFieldNameDirectionIn()].range}
              isvalid={Data.fieldIsValid(this.getFieldNameDirectionIn())}
              logic={true} />
          </td>
          <td>
            <Input
              index="1"
              onChange={this.setType.bind(this)}
              id={this.getFieldNameType()}
              default={this.props.data[this.getFieldNameType()].default}
              value={this.props.data[this.getFieldNameType()].value}
              datatype={this.props.data[this.getFieldNameType()].type}
              datarange={this.props.data[this.getFieldNameType()].range}
              isvalid={Data.fieldIsValid(this.getFieldNameType())}
              logic={true} />
          </td>
          <td>—</td>
          <td>—</td>
          <td>—</td>
          <td>
            <Input
              onChange={this.updateField.bind(this)}
              id={this.getFieldNameOSM()}
              default={this.props.data[this.getFieldNameOSM()].default}
              value={this.props.data[this.getFieldNameOSM()].value}
              datatype={this.props.data[this.getFieldNameOSM()].type}
              datarange={this.props.data[this.getFieldNameOSM()].range}
              isvalid={Data.fieldIsValid(this.getFieldNameOSM())} />
          </td>
          <td>
            <Input
              onChange={this.updateField.bind(this)}
              id={this.getFieldNameOverrEn()}
              default={this.props.data[this.getFieldNameOverrEn()].default}
              value={this.props.data[this.getFieldNameOverrEn()].value}
              datatype={this.props.data[this.getFieldNameOverrEn()].type}
              datarange={this.props.data[this.getFieldNameOverrEn()].range}
              isvalid={Data.fieldIsValid(this.getFieldNameOverrEn())} />
          </td>
          <td>
            <Input
              onChange={this.updateField.bind(this)}
              id={this.getFieldNameOverrVal()}
              default={this.props.data[this.getFieldNameOverrVal()].default}
              value={this.props.data[this.getFieldNameOverrVal()].value}
              datatype={this.props.data[this.getFieldNameOverrVal()].type}
              datarange={this.props.data[this.getFieldNameOverrVal()].range}
              isvalid={Data.fieldIsValid(this.getFieldNameOverrVal())} />
          </td>
        </tr>
      )
    } else {
      return (
        <tr>
          <td className="row-label">Universal Channel {this.props.index}</td>
          <td>
            <Input
              onChange={this.setDirection.bind(this)}
              id={this.getFieldNameDirectionIn()}
              default={this.props.data[this.getFieldNameDirectionIn()].default}
              value={this.props.data[this.getFieldNameDirectionIn()].value}
              datatype={this.props.data[this.getFieldNameDirectionIn()].type}
              datarange={this.props.data[this.getFieldNameDirectionIn()].range}
              isvalid={Data.fieldIsValid(this.getFieldNameDirectionIn())}
              logic={true} />
          </td>
          <td>
            <Input
              onChange={this.setType.bind(this)}
              id={this.getFieldNameType()}
              default={this.props.data[this.getFieldNameType()].default}
              value={this.props.data[this.getFieldNameType()].value}
              datatype={this.props.data[this.getFieldNameType()].type}
              datarange={this.props.data[this.getFieldNameType()].range}
              isvalid={Data.fieldIsValid(this.getFieldNameType())}
              logic={true} />
          </td>
          <td>
            {parseInt(this.state.type) !== 19 &&
            <Input
              index={this.getFilterIndex()}
              onChange={this.updateField.bind(this)}
              id={this.getFieldNameFilter()}
              default={this.props.data[this.getFieldNameFilter()].default}
              value={this.props.data[this.getFieldNameFilter()].value}
              datatype={this.props.data[this.getFieldNameFilter()].type}
              datarange={this.props.data[this.getFieldNameFilter()].range}
              isvalid={Data.fieldIsValid(this.getFieldNameFilter())} />
            }
            {parseInt(this.state.type) === 19 &&
              <span>—</span>
            }
          </td>
          <td>{this.getMinMaxField("Min")}</td>
          <td>{this.getMinMaxField("Max")}</td>
          <td>—</td>
          <td>—</td>
          <td>—</td>
        </tr>
      )
    }
  }
}

export default UniversalChannelRow;
