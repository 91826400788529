import React from 'react';
import { CSSTransition } from 'react-transition-group';

import Data from '../../Data';
import * as Constants from '../../Constants';
import Toggle from '../Toggle/';

import './Input.scss';

class Input extends React.Component {

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  handleFocus = (event) => event.target.select()

  onChange(event) {
    Data.updateField(this.props.id, this.props.datatype, event);

    if (this.props.logic) {
      Data.logicValidation();
    }
  }

  render() {
    let item = null
    let select = false;
    let classNames = '';
    let num = 0;
    let invalidMessageClass = "invalid-message"
    let invalidMessage = "";

    if (("isvalid" in this.props) && !this.props.isvalid) {
      classNames = 'invalid';
      invalidMessage = Data.getErrorMsg(this.props.id);

      if (this.props.validationMessageAlign) {
        invalidMessageClass += " invalid-message-"+this.props.validationMessageAlign;
      }
    }

    if (Array.isArray(this.props.datarange)) {
      select = true;
    }

    switch (this.props.datatype) {
      case Constants.UINT:
      case Constants.REAL:
        if (!select) {
          var step = "";
          var pattern = "";

          if (this.props.datatype === Constants.REAL) {
            pattern = new RegExp('^\\d*(\\.\\d{0,6})?$').toString();
            num = Number(String("1"+Constants.countDecimals(this.props.default)));

            //  Hacky way of enabling a single decimal step for fields
            //  that have values to 3 decimals places only
            if (num > 3)
              step = 1 / num;
            else
              step = "0.1"
          }
          if (this.props.datarange === Object(this.props.datarange)) {
            item = (
              <input
                type="number"
                className={`int ${classNames}`}
                onFocus={this.handleFocus}
                value={this.props.value}
                min={this.props.datarange["min"]}
                max={this.props.datarange["max"]}
                onChange={this.onChange}
                id={this.props.id}
                key={this.props.id}
                step={step}
                pattern={pattern}
                placeholder={this.props.placeholder}
                required
                />
            )
          }
          else {
            item = (
              <input
                type="number"
                className={`int ${classNames}`}
                onFocus={this.handleFocus}
                onBlur={this.onBlur}
                value={this.props.value}
                onChange={this.onChange}
                id={this.props.id}
                key={this.props.id}
                />
            )
          }
        } else {
          var index = this.props.index ? this.props.index : 0;
          var items = this.props.datarange[index].value;

          if (typeof items === 'object') {
          //  Check if value is a valid options
            if (this.props.value > Object.keys(items).length) {
              Data.resetField(this.props.id);
              console.log('resetting field: '+this.props.id)
              console.log(this.props.value, Object.keys(items).length)
            }

            item = (
              <select
                value={this.props.value}
                onChange={this.onChange}
                id={this.props.id}>
              {Object.keys(items).map((item, i) =>
                <option key={this.props.id+items[item]} value={item}>{items[item]}</option>
              )}
              </select>
            )
          } else {
          //  Check if value is a valid options
            if (this.props.value > this.props.datarange.length) {
              Data.resetField(this.props.id);
              console.log('resetting field')
            }

            item = (
              <select
                value={this.props.value}
                onChange={this.onChange}
                id={this.props.id}>
              {this.props.datarange.map((key) =>
                <option key={key.value} value={key.key}>{key.value}</option>
              )}
              </select>
            )
          }
        }
        break;

      case Constants.BOOL:
        const checked = (String(this.props.value).toUpperCase() === "TRUE" ? true : false);
        
        item = (
          <Toggle
            checked={checked}
            {...this.props} />
        )
        break;

      default:
    }

    return (
      <span className="input">
        <CSSTransition timeout={250} classNames="invalid-message" in={invalidMessage!==""} appear unmountOnExit>
          <div className={invalidMessageClass}>
          {invalidMessage}
          </div>
        </CSSTransition>
        {item}
      </span>
    );
  }
}

export default Input
