import React from 'react';
import * as Constants from '../../Constants';
import Data from '../../Data';

import './Toggle.scss';
var classNames = require('classnames');

class Toggle extends React.Component {
  constructor(props) {
    super(props);
    this.box = React.createRef();
    this.state = {
      checked: this.props.checked
    }
  }
  static getDerivedStateFromProps(props,state) {
    state.checked=props.checked;
    return state;
  }
  toggle() {
    if (this.state.checked) {
      this.setState({checked:false})
      this.box.current.checked = false;
    } else {
      this.setState({checked:true})
      this.box.current.checked = true;
    }
    var checked = this.box.current.checked;
    Data.updateField(this.props.id, Constants.BOOL, {target:{checked:checked}});
  }
  render() {
    var classes = classNames({
      toggle: true,
      on: this.state.checked
    })

    return (
      <div className={classes} onClick={this.toggle.bind(this)}>
        <div className="toggle-handle"></div>
        <input ref={this.box} type="checkbox" id={this.props.id} onChange={this.toggle} checked={this.props.checked}/>
      </div>
    )
  }
}

export default Toggle;
