import React from 'react';
import Data from './Data';
import Header from './components/Header/Header';
import Spinner from './components/Spinner';
import Welcome from './components/Welcome';
import General from './components/General';
import IO from './components/IO';
import Functions from './components/Functions'
import Help from './components/Help'
import {
  BrowserRouter as Router,
  Switch,
  Route,

  // Prompt
} from "react-router-dom";
import { CSSTransition } from 'react-transition-group';

// Styling bootstamp
import './App.scss';

class App extends React.Component {

  _raw = "";

  constructor(props) {
    super(props);

    this.state = {
      data: null,
      loading: true,
      showSplash: true,
      errors: false
    }

    this.onDataLoad = this.onDataLoad.bind(this);
    this.isLoading = this.isLoading.bind(this);
    this.isError = this.isError.bind(this);
  }

  componentDidMount() {
    Data.setCallback(this.onDataLoad);
    Data.setLoadingFunction(this.isLoading);
    Data.setErrorCallback(this.isError);
    Data.loadDefault();
  }

  onDataLoad() {
    this.setState({data: Data._data, categories: Data._categories, loading: false});
  }

  isLoading() {
    this.setState({loading:true})
  }

  isError(truefalse) {
    this.setState({errors: truefalse})
  }

  getGeneralData() {
    return Data.getCategoryData("General");
  }

  getIOData() {
    return Data.getCategoryData("Local");
  }

  getFunctionsData() {
    return Data.getCategoryData("Local functions");
  }

  noErrors() {
    return Object.keys(Data.getErrors()).length === 0;
  }

  closeSplashScreen = (e) => {
    this.setState({
      showSplash: false
    })
  }

  saveToFile() {
    if (!this.noErrors()) {
      return;
    }

    const element = document.createElement("a");
    const file = new Blob([Data.getDataPackage()], {type: 'text/plain'});
    // const date = new Date();

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file, "EXPORT_.txt");
    } else {
      element.href = URL.createObjectURL(file);
      // element.download = "EXPORT_"+date.getTime()+".txt";
      element.download = "EXPORT_.txt";
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
      element.parentNode.removeChild(element);
    }
  }

  render() {
    return (
      <div className="App">
        <CSSTransition timeout={500} classNames="spinner" in={this.state.loading} appear unmountOnExit>
          <Spinner />
        </CSSTransition>
        {this.state.data &&
          <div>
            <CSSTransition in={this.state.data!==null} classNames="spinner" timeout={500}>
              <form
                onSubmit={event => {
                  event.preventDefault();
                  this.saveToFile();
                }}
              >
                <Router>
                  <CSSTransition in={this.state.showSplash} classNames="spinner" timeout={500} appear unmountOnExit>
                    <Welcome closeFunc={this.closeSplashScreen} />
                  </CSSTransition>
                  <Header errors={this.state.errors} />
                  <div>
                    <Switch>
                      <Route exact path="/" render={(props) => <General data={this.getGeneralData()} {...props} />} />
                      <Route path="/io/:id?" render={(props) => <IO data={this.getIOData()} {...props} />} />
                      <Route path="/functions/:id?" render={(props) => <Functions data={this.getFunctionsData()} {...props} />} />
                      <Route path="/help"><Help /></Route>
                    </Switch>
                  </div>
                </Router>
              </form>
            </CSSTransition>
          </div>
        }
      </div>
    );
  }
}

export default App;
