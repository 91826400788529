import React from 'react';
import Section from '../Section';
import Input from '../Input';
import Data from '../../Data';
import * as Constants from '../../Constants';
import {
  Link
} from "react-router-dom";

import './Functions.scss';

class Functions extends Section {
  constructor(props) {
    super(props);

    this.state = {
      id: 0
    }
  }

  static getDerivedStateFromProps(props, state) {
    state.id=(props.match.params.id!==undefined?props.match.params.id:0);
    return state;
  }

  getTabSet() {
    return (
      <div className="tabset-sticky">
        <ul>
          <li><Link onClick={this.onLinkChange} to="/functions/" className={parseInt(this.state.id)===0?"selected":""}>Local</Link></li>
          {[...Array(Constants.IO_EXPANSIONS)].map((e, i) =>
            <li key={`Expansion ${i+1}`}><Link onClick={this.onLinkChange} to={`/functions/${i+1}`} className={i+1===parseInt(this.state.id)?"selected":""}>Expansion {i+1}</Link></li>
          )}
        </ul>
      </div>
    )
  }

  getSectionInputs(section) {
    const inputs = {};
    const data = parseInt(this.state.id) === 0 ? Data.getCategoryData('Local functions') : Data.getCategoryData('Expansion ' + this.state.id + ' functions');

    for (var i in data) {
      if(String(data[i].label).toUpperCase().startsWith(section.toUpperCase())) {
        inputs[i] = {
          value: data[i].value,
          default: data[i].default,
          type: data[i].type,
          range: data[i].range,
          label: this.sanitizeLabel(section,data[i].label)
        }
      }
    }

    var items = Object.entries(inputs).map(([key, item]) =>
      <div className="row" key={key}>
        <div className="row-label">{item.label}</div>
        <div className="row-input">
          <Input
            id={key}
            default={item.default}
            value={item.value}
            datatype={item.type}
            datarange={item.range}
            isvalid={Data.fieldIsValid(key)} />
        </div>
      </div>
    );

    return items;
  }

  sanitizeLabel(section,label) {
    return String(label).replace(section,"").replace(" – ","").replace(" - ","");
  }

  isValidID() {
    return (parseInt(this.state.id) > -1 && parseInt(this.state.id) < 11);
  }

  getTitle() {
    if (parseInt(this.state.id) === 0) {
      return "Local settings"
    }

    return "Expansion " + this.state.id + " settings"
  }

  render() {
    if (!this.isValidID()) {
      return (
        <div>Not found</div>
      )
    }
    return (
      <div className="functions">
        <div className="tabset tabset-sticky">
          <h1>Functions setup</h1>
          {this.getTabSet()}
        </div>
        <div className="main">
          <div className="fixed-column">
            <div className="columns">
              <div className="columns-section">
                <h3>{Constants.FUNC_COUNT_1}</h3>
                <div>{this.getSectionInputs(Constants.FUNC_COUNT_1)}</div>
              </div>
              <div className="columns-section">
                <h3>{Constants.FUNC_COUNT_2}</h3>
                <div>{this.getSectionInputs(Constants.FUNC_COUNT_2)}</div>
              </div>
              <div className="columns-section">
                <h3>{Constants.FUNC_DI_COPY_1}</h3>
                <div>{this.getSectionInputs(Constants.FUNC_DI_COPY_1)}</div>
              </div>
              <div className="columns-section">
                <h3>{Constants.FUNC_DI_COPY_2}</h3>
                <div>{this.getSectionInputs(Constants.FUNC_DI_COPY_2)}</div>
              </div>
              <div className="columns-section">
                <h3>{Constants.FUNC_A_RESCALE_1}</h3>
                <div>{this.getSectionInputs(Constants.FUNC_A_RESCALE_1)}</div>
              </div>
              <div className="columns-section">
                <h3>{Constants.FUNC_A_RESCALE_2}</h3>
                <div>{this.getSectionInputs(Constants.FUNC_A_RESCALE_2)}</div>
              </div>
              <div className="columns-section">
                <h3>{Constants.FUNC_THERMO_1}</h3>
                <div>{this.getSectionInputs(Constants.FUNC_THERMO_1)}</div>
              </div>
              <div className="columns-section">
                <h3>{Constants.FUNC_THERMO_2}</h3>
                <div>{this.getSectionInputs(Constants.FUNC_THERMO_2)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Functions
