import React from 'react';
import {
  Link
} from "react-router-dom";

import Data from '../../Data';

class ErrorPanel extends React.Component {
  resetField(event, field) {
    event.preventDefault();
    event.stopPropagation();
    Data.resetField(field);
  }

  render() {
    const errors = Data.getErrors();
    const errorRows = Object.keys(errors).map((field,i) =>
      <Link to={errors[field].path+(!errors[field].logicOnly ? "?field="+field : "")} key={i}>
        <div className="error-panel-row">
          <div className="error-panel-row-icon">
            {!errors[field].logicOnly &&
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M11 15h2v2h-2v-2zm0-8h2v6h-2V7zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/></svg>
            }
            {errors[field].logicOnly &&
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                <g>
                  <rect fill="none" height="24" width="24"/>
                </g>
                <g>
                  <path d="M8,8H6v7c0,1.1,0.9,2,2,2h9v-2H8V8z"/>
                  <path d="M20,3h-8c-1.1,0-2,0.9-2,2v6c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2V5C22,3.9,21.1,3,20,3z M20,11h-8V7h8V11z"/>
                  <path d="M4,12H2v7c0,1.1,0.9,2,2,2h9v-2H4V12z"/>
                </g>
                <g display="none"><g display="inline"/><g display="inline"><path d="M8,8H6v7c0,1.1,0.9,2,2,2h9v-2H8V8z"/><path d="M20,3h-8c-1.1,0-2,0.9-2,2v6c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2V5C22,3.9,21.1,3,20,3z M20,11h-8V7h8V11z"/><path d="M4,12H2v7c0,1.1,0.9,2,2,2h9v-2H4V12z"/></g></g></svg>
            }
          </div>
          <div className="error-panel-row-details">
            <div>{errors[field].label}</div>
            <div>{errors[field].error}</div>
            <code>{errors[field].path}</code>
          </div>
          {!errors[field].logicOnly &&
          <div className="error-panel-row-reset" onClick={(e) => this.resetField(e,field)} title="Reset to default">
            <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24" width="20">
              <path d="M0 0h24v24H0z" fill="none"/>
              <path d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6 0 1.01-.25 1.97-.7 2.8l1.46 1.46C19.54 15.03 20 13.57 20 12c0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6 0-1.01.25-1.97.7-2.8L5.24 7.74C4.46 8.97 4 10.43 4 12c0 4.42 3.58 8 8 8v3l4-4-4-4v3z"/>
            </svg>
          </div>
          }
        </div>
      </Link>
    );

    return (
      <div className="error-panel" style={{marginLeft: this.props.marginLeft+"px"}}>
        <div className="error-panel-scroll">
          <div className="error-panel-heading">
            <div>
              <div className="error-panel-description">Correct the following issues before saving the file</div>
            </div>
          </div>
          {errorRows}
        </div>
      </div>
    )
  }
}

export default ErrorPanel;
