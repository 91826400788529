import React from 'react';
import Section from '../Section';
import Input from './../Input';
import * as Constants from '../../Constants';
import Data from '../../Data';

class General extends Section {
  getRow(item, key) {
    if (item.type===Constants.SUBTITLE) {
      return (
        <div className="row" key={key}>
          <div className="row-label"><h3>{item.label}</h3></div>
        </div>
      );
    } else {
      return (
        <div className="row" key={key}>
          <div className="row-label">{item.label}</div>
          <div className="row-input">
            <Input
              id={key}
              default={item.default}
              value={item.value}
              datatype={item.type}
              datarange={item.range}
              isvalid={Data.fieldIsValid(key)} />
          </div>
        </div>
      );
    }
  }

  render() {
    var items = Object.entries(this.props.data).map(([key, item]) =>
      this.getRow(item, key)
    );

    return (
      <div className="main general">
        <div className="fixed-column">
        <h1>General settings</h1>
        {items}
        </div>
      </div>
    );
  }
}

export default General;
