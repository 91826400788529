export const UINT = "UINT";
export const BOOL = "BOOL";
export const REAL = "REAL";
export const SUBTITLE = "SUBTITLE";
export const DECIMAL_PLACES = 6;
export const IO_EXPANSIONS = 10;
export const IO_DIGITAL = 6;

export const FUNC_COUNT_1 = "Counter 1";
export const FUNC_COUNT_2 = "Counter 2";
export const FUNC_DI_COPY_1 = "Digital Input Copy 1";
export const FUNC_DI_COPY_2 = "Digital Input Copy 2";
export const FUNC_A_RESCALE_1 = "Analog Value Rescale 1";
export const FUNC_A_RESCALE_2 = "Analog Value Rescale 2";
export const FUNC_THERMO_1 = "Thermostat 1";
export const FUNC_THERMO_2 = "Thermostat 2";

export const DEF_FILE = "/data/default.xlsx";

export const IO_DEF_DIRECT_IN = {}

export const countDecimals = function (value) {
  if(Math.floor(value) === value) return 0;
  return value.toString().split(".")[1];
}
