import React from 'react';
import './Spinner.scss';

class Spinner extends React.Component {
  render() {
    return (
      <div className="spinner">
        <div className="lds-ripple"><div></div><div></div></div>
      </div>
    );
  }
}

export default Spinner;
