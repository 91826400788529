import * as Constants from './Constants';

import XLSX from 'xlsx';

class Data {

  static _data = {};
  static _categories = {};
  static _loading = function() {};
  static _callBack = function() {};
  static _isError = function() {};
  static _isDefault = false;
  static _errors = {};

  static setCallback(callback) {
    this._callBack = callback;
  }

  static setLoadingFunction(func) {
    this._loading = func;
  }

  static setErrorCallback(func) {
    this._isError = func;
  }

  static loadDefault(callback) {
    this._loading();
    fetch(Constants.DEF_FILE)
      .then(res => res.arrayBuffer())
      .then((res) => {
        var workbook = XLSX.read(new Uint8Array(res), {
          type: 'array'
        })
        var json = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
        this._sanitize(json);
      });
  }

  static _sanitize(data) {
    var _data = {};
    var _categories = {};
    var _category = "General";

    _categories[_category] = [];

    this.resetErrors();

    for (var i in data) {
      var item = data[i];

      if (item["Variable"] === undefined) {
        _category = item["Parameter"];
        _categories[_category] = [];
      }
      else {
        _categories[_category].push(item["Variable"]);

        _data[item["Variable"]] = {
          label: item["Parameter"],
          type: item["DataType"],
          value: (item["DataType"]==="REAL"?Number(item["DefaultValue"]).toFixed(this.decimalPlaces(item["Variable"])):item["DefaultValue"]),
          default: (item["DataType"]==="REAL"?Number(item["DefaultValue"]).toFixed(this.decimalPlaces(item["Variable"])):item["DefaultValue"]),
          range: this.getRange(item["Range"]),
          category: _category
        };
      }
    };

    this._data = _data;
    this._categories = _categories;
    this._isDefault = true;

    this._callBack();
  }

  static decimalPlaces(variable) {
    if (String(variable).includes("Counter1_Scaling") ||
        String(variable).includes("Counter2_Scaling")) {
      return 3;
    }

    return 1;
  }

  static hasChanged() {
    return !this._isDefault;
  }

  static getRange(range) {
    if (range === undefined) {
      return null;
    }
    var obj = {}
    try {
      obj = JSON.parse(range);
    } catch (error) {
    }
    if (obj["range"]) {
      return obj["range"];
    }

    if (obj["options"]) {
      var options = [];

      for (var i in obj["options"]) {
        options.push({key: i, value: obj["options"][i]});
      }

      return options;
    }

    return obj;
  }

  static loadData(fileData) {
    this._loading();
    this.resetErrors();

    var contents = String(fileData).split(/\r\n|\n/);
    var data = this._data;

    for (var i in contents) {
      var parts = String(contents[i]).replace("\t\t", "\t").split("\t");

      if (parts.length > 2) {
        if (parts[0] in data) {
          if (data[parts[0]].type === Constants.REAL) {
            data[parts[0]].value = parseFloat(parts[2]);
          } else {
            data[parts[0]].value = parts[2];
          }
          this.validateField(parts[0]);
        }
      }
    }
    this._data = data;
    this.logicValidation();
    this._callBack();
  }

  static getDataPackage() {
    var output = "";

    for (var i in this._data) {
      var o=this._data[i];
      switch (o.type) {
        case Constants.REAL:
          o.value = Number(o.value).toFixed(Constants.DECIMAL_PLACES);
          break;
        case Constants.BOOL:
          o.value = String(o.value).toUpperCase();
          break;
        case Constants.SUBTITLE:
          continue;
        default:
      }
      output += i + "\t\t" + o.type + "\t" + o.value + "\r\n";
    }

    return output;
  }

  static updateField(field, type, e) {
    const data = Data._data;
    if (type !== Constants.BOOL) {
      data[field].value = e.target.value
    }
    else {
      data[field].value = String(e.target.checked).toUpperCase();
    }

    this._isDefault = false;

    this._data = data;
    this._callBack();

    return this.validateField(field);
  }

  static resetField(field) {
    if (!(field in this._data)) {
      return false;
    }
    this._data[field].value = this._data[field].default;
    this.removeError(field);
  }

  static getCategoryData(category) {
    var data = {};
    var items = Object(this._categories)[category];

    for (var i in items) {
      data[items[i]] = Object(this._data)[items[i]];
    }

    return data;
  }

  static setError(field, path, error, logicOnly = false) {
    if (field in this._errors) {
      return null;
    }
    this._errors[field] = {
      "label": (logicOnly ? field : this._data[field].label),
      "path": path,
      "error": error,
      "logicOnly": logicOnly
    };
    this._isError(true);
  }

  static removeError(field) {
    if (!(field in this._errors)) {
      return;
    }

    delete this._errors[field];

    if (this.errorCount() > 0) {
      this._isError(true);
    } else {
      this._isError(false);
    }
  }

  static resetErrors() {
    this._errors = {};
    this._isError(false);
  }

  static getErrors() {
    return this._errors;
  }

  static getErrorMsg(field) {
    if (field in this._errors) {
      return this._errors[field].error;
    }

    return '';
  }

  static errorCount() {
    return Object.keys(this._errors).length;
  }

  static validateField(field) {
    if (!(field in this._data)) {
      return false;
    }

    const fieldObject = this._data[field];
    const range = fieldObject.range;
    let valid = true;
    let errorMessage = "";

    if (fieldObject.value === "") {
      valid = false;
      errorMessage = "Cannot be blank";
    }

    switch (fieldObject.type) {
      case Constants.REAL:
      case Constants.UINT:
        if (isNaN(fieldObject.value)) {
          valid = false;
        } else {
          if ("min" in range && "max" in range) {
            errorMessage = "Value must be between " + range["min"] + " and " + range["max"]
            if (fieldObject.value < range["min"] || fieldObject.value > range["max"]) {
              valid = false;
            }
          } else {
            // console.log(fieldObject.value, range.length)
            // console.log(range)
            // if (fieldObject.value > range.length) {
            //   errorMessage = "Please choose an option"
            //   valid = false;
            // }
          }
        }
        break;
      default:
    }

    if (!valid) {
      this.setError(
        field,
        this.generateFieldUrl(field),
        errorMessage
      );
    }
    else {
      this.removeError(field);
    }

    return valid;
  }

  static fieldIsValid(field) {
    return !(field in this._errors);
  }

  static generateFieldUrl(field) {
    if (!(field in this._data)) {
      return null;
    }

    const category = this._data[field].category;

    switch (category) {
      case "General":
        return "/";

      case "Local functions":
        return "/functions";

      case "Local":
        return "/io";

      default:
        const parts = String(category).toUpperCase().split(" ");

        if (parts.length === 3) {
          return "/functions/"+parts[1];
        }
        else {
          return "/io/"+parts[1];
        }
    }
  }

  static logicValidation() {

  //  Check 1: This is a max of 5 universal channels as "Output" with type "0-10V"
    let check1Counter = 0;
    let check1LocalCounter = 0;
    let check1outputTypeCount = [];
    const check1ErrorTitle = "IO {device}: maximum 0-10V Outputs exceeded";
    const check1ErrorMessage = "You exceeded the maximum allowed number of 0-10V outputs on the {device} device";

  //  Check 2: This is a max of 4 universal channels as "Input" with type "0-20mA" or type "4-20mA"
    let check2Counter = 0;
    let check2LocalCounter = 0;
    let check2outputTypeCount = [];
    const check2ErrorTitle = "IO {device}: maximum current inputs exceeded";
    const check2ErrorMessage = "You exceeded the maximum allowed number of Current Inputs on the {device} device";

  //  Check 3: You can have maximum 2 Universal Channels configured as “Input” with type “PULSE COUNT” OR “FREQ. MEAS”
    let check3Counter = 0;
    let check3LocalCounter = 0;
    let check3outputTypeCount = [];
    const check3ErrorTitle = "IO {device}: maximum PULSE COUNT or FREQ. MEAS inputs exceeded";
    const check3ErrorMessage = "You exceeded the maximum allowed number of PULSE COUNT or FREQ. MEAS Inputs on the {device} device";

  //  Check 4: You can have maximum 2 Universal Channels configured as “Input” with type “PULSE COUNT” OR “FREQ. MEAS”
    let check4Counter = 0;
    let check4LocalCounter = 0;
    let check4outputTypeCount = [];
    const check4ErrorTitle = "IO {device}: maximum 0-5V(Link) inputs exceeded";
    const check4ErrorMessage = "You exceeded the maximum allowed number of 0-5V(Link) Inputs on the {device} device";

  //  Check 5: If any Universal Channel is configured as “Input” with type “PT100”, the next Universal Channel cannot be used and it has to be configured as “Not Used”
    let check5Counter = 0;
    let check5LocalCounter = 0;
    let check5outputTypeCount = [];
    const check5ErrorTitle = "IO {device}: PT100 sensors use 2 consecutive Universal Channels";
    const check5ErrorMessage = "PT100 sensors use 2 consecutive Universal Channels and the second one should be configured as Not Used. Please check your configuration on the {device} device";

    for (let i=1; i <= Constants.IO_EXPANSIONS; i++) {
      check1Counter = 0;
      check2Counter = 0;
      check3Counter = 0;
      check4Counter = 0;
      check5Counter = 0;

      let localDirection = "U"+i+".Direction_In";
      let localType = "U"+i+".Type_In";
      let localNextType = "U"+(i+1)+".Type_In";

      this.removeError(check1ErrorTitle.replace("{device}", "Expansion " + i));
      this.removeError(check2ErrorTitle.replace("{device}", "Expansion " + i));
      this.removeError(check3ErrorTitle.replace("{device}", "Expansion " + i));
      this.removeError(check4ErrorTitle.replace("{device}", "Expansion " + i));
      this.removeError(check5ErrorTitle.replace("{device}", "Expansion " + i));

    //  Perform check 1 on Local
      if (parseInt(this._data[localDirection].value) === 1 &&
          parseInt(this._data[localType].value) === 0) {
        check1LocalCounter++;
      }

    //  Perform check 2 on Local
      if (parseInt(this._data[localDirection].value) === 0 &&
          (parseInt(this._data[localType].value) === 14 ||
          parseInt(this._data[localType].value) === 15)) {
        check2LocalCounter++;
      }

    //  Perform check 3 on Local
      if (parseInt(this._data[localDirection].value) === 0 &&
          (parseInt(this._data[localType].value) === 17 ||
          parseInt(this._data[localType].value) === 18)) {
        check3LocalCounter++;
      }

    //  Perform check 4 on Local
      if (parseInt(this._data[localDirection].value) === 0 &&
          parseInt(this._data[localType].value) === 12) {
        check4LocalCounter++;
      }

    //  Perform check 5 on Local
      if (i < Constants.IO_EXPANSIONS) {
        if (parseInt(this._data[localDirection].value) === 0 &&
            parseInt(this._data[localType].value) === 5 &&
            parseInt(this._data[localNextType].value) !== 19 ) {
          check5LocalCounter++;
        }
      }

      for (let j=1; j <= Constants.IO_EXPANSIONS; j++) {
        let direction = "E"+i+"_Cfg_Net.Dir_U"+j;
        let type = "E"+i+"_Mask.Type_U"+j;
        let nextType = "E"+i+"_Mask.Type_U"+(j+1);

      //  Perform check 1
        if (parseInt(this._data[direction].value) === 1 &&
            parseInt(this._data[type].value) === 0) {
          check1Counter++;
        }

      //  Perform check 2
        if (parseInt(this._data[direction].value) === 0 &&
            (parseInt(this._data[type].value) === 14 ||
            parseInt(this._data[type].value) === 15)) {
          check2Counter++;
        }

      //  Perform check 3
        if (parseInt(this._data[direction].value) === 0 &&
            (parseInt(this._data[type].value) === 17 ||
            parseInt(this._data[type].value) === 18)) {
          check3Counter++;
        }

      //  Perform check 4
        if (parseInt(this._data[direction].value) === 0 &&
            parseInt(this._data[type].value) === 12) {
          check4Counter++;
        }

      //  Perform check 5
        if (j < Constants.IO_EXPANSIONS) {
          // console.log(this._data[nextType].value)
          if (parseInt(this._data[direction].value) === 0 &&
              parseInt(this._data[type].value) === 5 &&
              parseInt(this._data[nextType].value) !== 19 ) {
            check5Counter++;
          }
        }
      }

      if (check1Counter > 5) {
        check1outputTypeCount.push(i);
      }

      if (check2Counter > 4) {
        check2outputTypeCount.push(i);
      }

      if (check3Counter > 2) {
        check3outputTypeCount.push(i);
      }

      if (check4Counter > 2) {
        check4outputTypeCount.push(i);
      }

      if (check5Counter > 0) {
        check5outputTypeCount.push(i);
      }
    }

//  Set errors for check 1
    if (check1LocalCounter > 5) {
      this.setError(
        check1ErrorTitle.replace("{device}", "Local"),
        "/io/",
        check1ErrorMessage.replace("{device}", "Local"),
        true
      );
    } else {
      this.removeError(check1ErrorTitle.replace("{device}", "Local"));
    }

    if (check1outputTypeCount.length > 0) {
      for (let i = 0; i < check1outputTypeCount.length; i++) {
        this.setError(
          check1ErrorTitle.replace("{device}", "Expansion " + check1outputTypeCount[i]),
          "/io/"+check1outputTypeCount[i],
          check1ErrorMessage.replace("{device}", "Expansion " + check1outputTypeCount[i]),
          true
        );
      }
    }

//  Set errors for check 2
    if (check2LocalCounter > 4) {
      this.setError(
        check2ErrorTitle.replace("{device}", "Local"),
        "/io/",
        check2ErrorMessage.replace("{device}", "Local"),
        true
      );
    } else {
      this.removeError(check2ErrorTitle.replace("{device}", "Local"));
    }

    if (check2outputTypeCount.length > 0) {
      for (let i = 0; i < check2outputTypeCount.length; i++) {
        this.setError(
          check2ErrorTitle.replace("{device}", "Expansion " + check2outputTypeCount[i]),
          "/io/"+check2outputTypeCount[i],
          check2ErrorMessage.replace("{device}", "Expansion " + check2outputTypeCount[i]),
          true
        );
      }
    }

//  Set errors for check 3
    if (check3LocalCounter > 2) {
      this.setError(
        check3ErrorTitle.replace("{device}", "Local"),
        "/io/",
        check3ErrorMessage.replace("{device}", "Local"),
        true
      );
    } else {
      this.removeError(check3ErrorTitle.replace("{device}", "Local"));
    }

    if (check3outputTypeCount.length > 0) {
      for (let i = 0; i < check3outputTypeCount.length; i++) {
        this.setError(
          check3ErrorTitle.replace("{device}", "Expansion " + check3outputTypeCount[i]),
          "/io/"+check3outputTypeCount[i],
          check3ErrorMessage.replace("{device}", "Expansion " + check3outputTypeCount[i]),
          true
        );
      }
    }

//  Set errors for check 4
    if (check4LocalCounter > 2) {
      this.setError(
        check4ErrorTitle.replace("{device}", "Local"),
        "/io/",
        check4ErrorMessage.replace("{device}", "Local"),
        true
      );
    } else {
      this.removeError(check4ErrorTitle.replace("{device}", "Local"));
    }

    if (check4outputTypeCount.length > 0) {
      for (let i = 0; i < check4outputTypeCount.length; i++) {
        this.setError(
          check4ErrorTitle.replace("{device}", "Expansion " + check4outputTypeCount[i]),
          "/io/"+check4outputTypeCount[i],
          check4ErrorMessage.replace("{device}", "Expansion " + check4outputTypeCount[i]),
          true
        );
      }
    }

//  Set errors for check 5
    if (check5LocalCounter > 0) {
      this.setError(
        check5ErrorTitle.replace("{device}", "Local"),
        "/io/",
        check5ErrorMessage.replace("{device}", "Local"),
        true
      );
    } else {
      this.removeError(check5ErrorTitle.replace("{device}", "Local"));
    }

    if (check5outputTypeCount.length > 0) {
      for (let i = 0; i < check5outputTypeCount.length; i++) {
        this.setError(
          check5ErrorTitle.replace("{device}", "Expansion " + check5outputTypeCount[i]),
          "/io/"+check5outputTypeCount[i],
          check5ErrorMessage.replace("{device}", "Expansion " + check5outputTypeCount[i]),
          true
        );
      }
    }
  }
}

export default Data;
