import React from 'react';

class Help extends React.Component {
  render() {
    return (
      <div className="main help">
        <div className="fixed-column copy">
          <h1>Need assistance?</h1>
          <p>Please contact <a href="mailto:support@innon.co.uk">support@innon.co.uk</a> for additional support or use the resources below.</p>
          <hr />
          <p><a href="http://support.innon.com/" target="_blank" rel="noopener noreferrer"><button className="primary" type="button"><svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24" width="20"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"/></svg>Manual and installation sheets</button></a></p>
          <p><a href="https://know.innon.com/" target="_blank" rel="noopener noreferrer"><button className="primary" type="button"><svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24" width="20"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"/></svg>Innon knowledge base</button></a></p>
        </div>
      </div>
    )
  }
}

export default Help;
