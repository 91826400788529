import React from 'react';
import {
  NavLink
} from "react-router-dom";
import { CSSTransition } from 'react-transition-group';

import Data from '../../Data';
import ErrorPanel from './ErrorPanel';

import './Header.scss';

const iconSize = 20;

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showErrorPanel: false
    }

    this.issuesButton = React.createRef();
  }

  selectFile = (e) => {
    e.preventDefault();
    if (Data.hasChanged()) {
      if (window.confirm("Loading a file will lose unsaved changes. Do you want to continue?")) {
        document.getElementById("file-input").click();
      }
    } else {
      document.getElementById("file-input").click();
    }
  }

  reset = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to reset?")) {
      Data.loadDefault();
    }
  }

  loadFromFile = (e) => {
    e.preventDefault();
    var reader = new FileReader();
    var file = document.getElementById("file-input").files[0];

    if (file===undefined) {
      return;
    }

    reader.onloadend = () => {
      Data.loadData(reader.result);
    }

  	reader.readAsText(file);
    document.getElementById("file-input").value = "";
  }

  toggleErrorPanel(e) {
    e.preventDefault();
    this.setState(prevState => ({
      showErrorPanel: !prevState.showErrorPanel
    }));
  }

  static getDerivedStateFromProps(props,state) {
    if (!props.errors) {
      state.showErrorPanel = false;
    }
    return state;
  }

  getIssuesButtonOffset() {
    const node = this.issuesButton.current;

    if (node !== null) {
      return (node.getBoundingClientRect().width / 2);
    }

    return 0;
  }

  render() {
    const saveDisabled = this.props.errors ? "primary disabled" : "primary";
    let issuesClasses = "issues";

    if (this.state.showErrorPanel) {
      issuesClasses += " active";
    }

    if (this.props.simple) {
      return (
        <div className="header">
          <div className="navigation">
            <ul>
              <li>
                <NavLink to="/"><img className="logo" alt="Innon configurator" src="/images/logo.png" /></NavLink>
              </li>
            </ul>
          </div>
        </div>
      );
    }

    return (
      <div className="header">
        <div className="navigation">
          <ul>
            <li>
              <NavLink to="/"><img className="logo" alt="Innon configurator" src="/images/logo.png" /></NavLink>
            </li>
            <li>
              <NavLink activeClassName="selected" exact to="/">General</NavLink>
            </li>
            <li>
              <NavLink activeClassName="selected" to="/io">I/O</NavLink>
            </li>
            <li>
              <NavLink activeClassName="selected" to="/functions">Functions</NavLink>
            </li>
            <li>
              <NavLink activeClassName="selected" to="/help">Help</NavLink>
            </li>
          </ul>
        </div>
        <div className="utils">
          <ul>
            <CSSTransition timeout={500} classNames="header-errors" in={this.props.errors} appear unmountOnExit>
              <li>
                <button ref={this.issuesButton} className={issuesClasses} onClick={this.toggleErrorPanel.bind(this)}>
                  <svg xmlns="http://www.w3.org/2000/svg" height={iconSize} viewBox="0 0 24 24" width={iconSize}><path d="M11 15h2v2h-2v-2zm0-8h2v6h-2V7zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/></svg>
                  Issues found <small style={{marginLeft: ".25rem"}}>({Data.errorCount()})</small>
                </button>
              </li>
            </CSSTransition>
            <li>
              <button onClick={this.reset}>
                <svg xmlns="http://www.w3.org/2000/svg" height={iconSize} viewBox="0 0 24 24" width={iconSize}>
                  <path d="M0 0h24v24H0z" fill="none"/>
                  <path d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6 0 1.01-.25 1.97-.7 2.8l1.46 1.46C19.54 15.03 20 13.57 20 12c0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6 0-1.01.25-1.97.7-2.8L5.24 7.74C4.46 8.97 4 10.43 4 12c0 4.42 3.58 8 8 8v3l4-4-4-4v3z"/>
                </svg>
                Start over
              </button>
            </li>
            <li>
              <button onClick={this.selectFile}>
                <svg xmlns="http://www.w3.org/2000/svg" height={iconSize} viewBox="0 0 24 24" width={iconSize}>
                  <path d="M0 0h24v24H0z" fill="none"/>
                  <path d="M5 4v2h14V4H5zm0 10h4v6h6v-6h4l-7-7-7 7z"/>
                </svg>
                Load config
              </button>
            </li>
            <li>
              <button type="submit" formNoValidate className={saveDisabled}>
                <svg xmlns="http://www.w3.org/2000/svg" height={iconSize} viewBox="0 0 24 24" width={iconSize}>
                  <path d="M0 0h24v24H0z" fill="none"/>
                  <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/>
                </svg>
                Download config
              </button>
            </li>
          </ul>
          <CSSTransition timeout={500} classNames="error-panel" in={this.state.showErrorPanel} appear unmountOnExit>
            <ErrorPanel marginLeft={this.getIssuesButtonOffset()} closeFunc={this.toggleErrorPanel.bind(this)} />
          </CSSTransition>
        </div>
        <input id="file-input" onChange={this.loadFromFile} type="file" style={{display: "none"}} />
      </div>
    )
  }
}

export default Header
