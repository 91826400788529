import React from 'react';
import Section from '../Section';
import Data from '../../Data';
import * as Constants from '../../Constants'
import Input from '../Input/';
import UniversalChannelRow from './UniversalChannelRow';
import {
  Link
} from "react-router-dom";

import './IO.scss';

class IO extends Section {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      field: null
    }
  }

  checkDirection(e) {
    Data.updateField(
      e.target.id,
      this.props.data[e.target.id].type,
      {
        target: {
          value:e.target.value
        }
      }
    )
  }

  static getDerivedStateFromProps(props, state) {
    state.id=parseInt(props.match.params.id!==undefined?props.match.params.id:0);
    return state;
  }

  getTabSet() {
    return (
      <ul>
        <li><Link onClick={this.onLinkChange} to="/io" className={this.state.id===0?"selected":""}>Local</Link></li>
        {[...Array(Constants.IO_EXPANSIONS)].map((e, i) =>
          <li key={`Expansion ${i+1}`}><Link onClick={this.onLinkChange} to={`/io/${i+1}`} className={i+1===parseInt(this.state.id)?"selected":""}>Expansion {i+1}</Link></li>
        )}
      </ul>
    )
  }

  getLocalTable() {
    var universalChannels = []

    for (var i=1; i <= Constants.IO_EXPANSIONS; i++) {
      universalChannels.push(
        <UniversalChannelRow key={"universal-channel"+i} index={i} {...this.props} />
      )
    }

    return universalChannels;
  }

  getExpansionTable() {
    var universalChannels = []

    for (var i=1; i <= Constants.IO_EXPANSIONS; i++) {
      universalChannels.push(
        <UniversalChannelRow key={"expansion"+this.state.id+"-channel"+i} expansion={this.state.id} index={i} data={Data.getCategoryData("Expansion "+this.state.id)} />
      )
    }

    return universalChannels;
  }

  getLocalDigital() {
    var _data = this.props.data;
    var digitalOutputs = [];

    for (var i=1; i <= Constants.IO_DIGITAL; i++) {
      digitalOutputs.push(
        <tr key={"digital-output"+i}>
          <td className="row-label">Digital Output {i}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <Input
              onChange={Data.updateField.bind(this, "DO"+i+".OSM_Value", _data["DO"+i+".OSM_Value"].type)}
              id={"DO"+i+".OSM_Value"}
              default={_data["DO"+i+".OSM_Value"].default}
              value={_data["DO"+i+".OSM_Value"].value}
              datatype={_data["DO"+i+".OSM_Value"].type}
              datarange={_data["DO"+i+".OSM_Value"].range}
              isvalid={Data.fieldIsValid("DO"+i+".OSM_Value")} />
          </td>
          <td>
            <Input
              onChange={Data.updateField.bind(this, "DO"+i+".Overr_En", _data["DO"+i+".Overr_En"].type)}
              id={"DO"+i+".Overr_En"}
              default={_data["DO"+i+".Overr_En"].default}
              value={_data["DO"+i+".Overr_En"].value}
              datatype={_data["DO"+i+".Overr_En"].type}
              datarange={_data["DO"+i+".Overr_En"].range}
              isvalid={Data.fieldIsValid("DO"+i+".Overr_En")} />
          </td>
          <td>
            <Input
              onChange={Data.updateField.bind(this, "DO"+i+".Overr_Val", _data["DO"+i+".Overr_Val"].type)}
              id={"DO"+i+".Overr_Val"}
              default={_data["DO"+i+".Overr_Val"].default}
              value={_data["DO"+i+".Overr_Val"].value}
              datatype={_data["DO"+i+".Overr_Val"].type}
              datarange={_data["DO"+i+".Overr_Val"].range}
              isvalid={Data.fieldIsValid("DO"+i+".Overr_Val")} />
          </td>
        </tr>
      )
    }

    return digitalOutputs;
  }

  getExpansionDigital() {
    var _data = Data.getCategoryData("Expansion " + this.state.id)
    var digitalOutputs = [];



    for (var i=1; i <= Constants.IO_DIGITAL; i++) {
      var fieldOSM = "E"+this.state.id+"_Cfg_Net.OSM_Val_DO"+i;
      var fieldEn = "E"+this.state.id+"_OvrLog.Ovr_En_DO"+i;
      var fieldVal = "E"+this.state.id+"_OvrLog.Ovr_Val_DO"+i;

      digitalOutputs.push(
        <tr key={"digital-output"+i}>
          <td className="row-label">Digital Output {i}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <Input
              onChange={Data.updateField.bind(this, fieldOSM, _data[fieldOSM].type)}
              id={fieldOSM}
              default={_data[fieldOSM].default}
              value={_data[fieldOSM].value}
              datatype={_data[fieldOSM].type}
              datarange={_data[fieldOSM].range}
              isvalid={Data.fieldIsValid(fieldOSM)} />
          </td>
          <td>
            <Input
              onChange={Data.updateField.bind(this, fieldEn, _data[fieldEn].type)}
              id={fieldEn}
              default={_data[fieldEn].default}
              value={_data[fieldEn].value}
              datatype={_data[fieldEn].type}
              datarange={_data[fieldEn].range}
              isvalid={Data.fieldIsValid(fieldEn)} />
          </td>
          <td>
            <Input
              onChange={Data.updateField.bind(this, fieldVal, _data[fieldVal].type)}
              id={fieldVal}
              default={_data[fieldVal].default}
              value={_data[fieldVal].value}
              datatype={_data[fieldVal].type}
              datarange={_data[fieldVal].range}
              isvalid={Data.fieldIsValid(fieldVal)} />
          </td>
        </tr>
      )
    }

    return digitalOutputs;
  }

  getLocalAnalog() {
    var _data = this.props.data;
    var analogOutputs = [];

    for (var i=1; i <= 2; i++) {
      analogOutputs.push(
        <tr key={"digital-output"+i}>
          <td className="row-label">Analog Output {i}</td>
          <td></td>
          <td>
            <Input
              onChange={Data.updateField.bind(this, "Y"+i+".Type_In", _data["Y"+i+".Type_In"].type)}
              id={"Y"+i+".Type_In"}
              default={_data["Y"+i+".Type_In"].default}
              value={_data["Y"+i+".Type_In"].value}
              datatype={_data["Y"+i+".Type_In"].type}
              datarange={_data["Y"+i+".Type_In"].range}
              isvalid={Data.fieldIsValid("Y"+i+".Type_In")} />
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <Input
              onChange={Data.updateField.bind(this, "Y"+i+".OSM_Value", _data["Y"+i+".OSM_Value"].type)}
              id={"Y"+i+".OSM_Value"}
              default={_data["Y"+i+".OSM_Value"].default}
              value={_data["Y"+i+".OSM_Value"].value}
              datatype={_data["Y"+i+".OSM_Value"].type}
              datarange={_data["Y"+i+".OSM_Value"].range}
              isvalid={Data.fieldIsValid("Y"+i+".OSM_Value")} />
          </td>
          <td>
            <Input
              onChange={Data.updateField.bind(this, "Y"+i+".Overr_En", _data["Y"+i+".Overr_En"].type)}
              id={"Y"+i+".Overr_En"}
              default={_data["Y"+i+".Overr_En"].default}
              value={_data["Y"+i+".Overr_En"].value}
              datatype={_data["Y"+i+".Overr_En"].type}
              datarange={_data["Y"+i+".Overr_En"].range}
              isvalid={Data.fieldIsValid("Y"+i+".Overr_En")} />
          </td>
          <td>
            <Input
              onChange={Data.updateField.bind(this, "Y"+i+".Overr_Val", _data["Y"+i+".Overr_Val"].type)}
              id={"Y"+i+".Overr_Val"}
              default={_data["Y"+i+".Overr_Val"].default}
              value={_data["Y"+i+".Overr_Val"].value}
              datatype={_data["Y"+i+".Overr_Val"].type}
              datarange={_data["Y"+i+".Overr_Val"].range}
              isvalid={Data.fieldIsValid("Y"+i+".Overr_Val")} />
          </td>
        </tr>
      )
    }

    return analogOutputs;
  }

  isValidID() {
    return (parseInt(this.state.id) > -1 && parseInt(this.state.id) < 11);
  }

  render() {
    if (!this.isValidID()) {
      return (
        <div>Not found</div>
      )
    }
    let table = null;

    if (this.state.id === 0) {
      table = (
        <tbody>
          {this.getLocalTable()}
          <tr>
            <td colSpan="9" className="spacer"></td>
          </tr>
          {this.getLocalDigital()}
          <tr>
            <td colSpan="9" className="spacer"></td>
          </tr>
          {this.getLocalAnalog()}
        </tbody>
      )
    } else {
      table = (
        <tbody>
          {this.getExpansionTable()}
          <tr>
            <td colSpan="9" className="spacer"></td>
          </tr>
          {this.getExpansionDigital()}
        </tbody>
      )
    }

    return (
      <div className="io">
        <div className="tabset tabset-sticky">
          <h1>Input and Output</h1>
          {this.getTabSet()}
        </div>
        <div className="main">
          <table cellSpacing="0" border="0">
            <colgroup>
              <col width="15%"></col>
              <col width="10%"></col>
              <col width="12%"></col>
              <col width="10%"></col>
              <col width="10%"></col>
              <col width="10%"></col>
              <col width="13%"></col>
              <col width="10%"></col>
              <col width="10%"></col>
            </colgroup>
            <thead>
              <tr>
                <th>Name</th>
                <th>Direction</th>
                <th>Type</th>
                <th>Filter/Speed/Current</th>
                <th>Min</th>
                <th>Max</th>
                <th>Output when offline</th>
                <th>Manual mode En</th>
                <th>Manual mode Value</th>
              </tr>
            </thead>
            {table}
          </table>
        </div>
      </div>
    );
  }
}

export default IO;
