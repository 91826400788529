import React from 'react';

let timer = null;

class Section extends React.Component {

  componentDidMount() {
    clearTimeout(timer);
    timer = setTimeout(function() {
      this.setScrollPosition();
    }.bind(this), 50);
    this.props.history.listen((location, action) => {
      clearTimeout(timer);
      timer = setTimeout(function() {
        this.setScrollPosition();
      }.bind(this), 250);
    });
  }

  setScrollPosition() {
    const search = this.props.location.search;
    let top = 0;

    if (search !== "") {
      let parts = search.split("?field=")
      let element = document.getElementById(parts[1]);
      if (element) {
        top = element.getBoundingClientRect().top + document.documentElement.scrollTop;

        if (top < window.innerHeight) {
          top = 0;
        }
      }
    }

    window.scrollTo({
      top: top,
      left: 0,
      behavior: 'smooth'
    });
  }
}

export default Section;
